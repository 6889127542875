<template>
  <div class="a-tree">
    <div class="header_fixed">
      <el-button
        style="margin-left: 10px"
        icon="el-icon-plus"
        size="small"
        @click="addEntity"
        plain>
        {{ $locale.main.button.add }}
      </el-button>
      <!-- <el-input  clearable size="mini" style="width: 55%; margin-left: 15%"
                :placeholder="$locale.main.fields.search"><i slot="prefix"
                class="el-input__icon el-icon-search"></i>
      </el-input> -->
    </div>
    <el-tree
      ref="Atree"
      lazy
      :load="loadNode"
      :data="data"
      node-key="id"
      :expand-on-click-node="false"
      @node-click="handleNodeClick"
      style="padding-top: 60px"
      :props="defaultProps">
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <div @click="clickNode ? openCard(data, node) : ''" v-html="node.data.name" style="width: 100%" :class="{ 'selected-node': selectedNode.id === node.data.id }"></div>
        <div v-show="selectedNode.id === node.data.id" >
          <el-button
            v-if="!clickNode"
            v-show="data.openBtnHidden"
            type="text"
            size="mini"
            @click.stop="openCard(data, node)">
            {{ $locale.main.button.open }}
          </el-button>
          <el-button
            v-show="data.hideAddCard"
            type="text"
            size="mini"
            @click.stop="addEntity(data, node)">
            {{ $locale.main.button.add }}
          </el-button>
          <el-popover placement="top" width="160" v-show="data.deleteBtnHidden">
            <p>{{ $locale.main.message.confirm }}</p>
            <div style="text-align: right; margin: 0">
              <el-button size="small" type="text">{{ $locale.main.button.cancel }}</el-button>
              <el-button type="primary" size="small" @click="deleteEntity(data)">{{ $locale.main.button.delete }}</el-button>
            </div>
            <el-button type="text" size="small" style="color:red; margin-left: 5px" slot="reference">{{ $locale.main.button.delete }}</el-button>
          </el-popover>
        </div>
      </div>
    </el-tree>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import RegistryCard from '@/components/RegistryCard'
import Registry from '@/components/Registry/Models/Registry'
import conditionsMixin from '@/components/InterfaceEditor/components/conditions_mixin'
import Node from 'element-ui/packages/tree/src/model/node'
export default {
  name: 'a-tree',
  mixins: [mixin, conditionsMixin],
  components: {
    RegistryCard
  },
  inject: {
    openRegistryCard: {
      default: () => {}
    }
  },
  props: {
    tree: {
      type: Object,
      default () {
        return {
          addBtnHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          },
          deleteBtnHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          },
          openBtnHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        }
      },
      editor: 'Tree'
    },
    defaultGroup: {
      description: 'Группировка (attr_N_)',
      type: String
    },
    clickNode: {
      type: Boolean,
      description: 'Открытие по ветке',
      default: false
    },
    frameGuid: {
      type: String,
      description: 'Карточка во фрейме',
      editor: 'Frames'
    },
    filters: {
      type: Array,
      editor: 'Filters'
    }
  },
  watch: {
    async dataFilters (val, oldval) {
      if (val.length || oldval.length) {
        this.data = await this.loadEntities()
      }
    }
  },
  data () {
    return {
      registryId: this.tree.registryId,
      data: [],
      selectedNode: {},
      defaultProps: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'children'
      }
    }
  },
  computed: {
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach(item => {
          let type = 'eq'
          if (item.isXref) {
            type = 'eqx'
          }
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias && this.getModel()[item.attribute] + '') {
              filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias},${type},${item.attribute}`)
          } else if (item.type === 'current_user' && item.alias) {
            filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
          }
        })
      }
      return filters
    }
  },
  methods: {
    handleNodeClick (data) {
      this.selectedNode = data
    },
    async addEntity (data, parent = null) {
      let isQuickAddCard = await this.isQuickAddCard()
      if (isQuickAddCard) {
        this.openQuickAddCard(isQuickAddCard, parent)

        return
      }
      let card = await this.getCardId()
      if (card) {
        let callback = async (recordid) => {
          let dataCard = await this.getCardData(recordid)
          let customNode = this.buildNode(dataCard, true)
          if (parent) {
            this.$refs.Atree.append(customNode, parent)
          } else {
            let node = new Node({ parent: this.$refs.Atree.root, store: this.$refs.Atree.store, data: customNode })
            node.level = 1
            this.$refs.Atree.root.childNodes.push(node)
          }
        }
        if (this.frameGuid) {
          let frame = (this.getDashboardComponents()[`component_${this.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', this.frameGuid)
            return false
          }

          frame.openCard({
            cardId: card.id,
            registryId: this.registryId,
            addCallback: callback,
            initialData: { [this.defaultGroup]: data.id }
          })
        } else {
          this.openRegistryCard({
            registryId: this.registryId,
            cardId: card.id,
            cardName: card.name,
            recordId: null,
            initialData: { [this.defaultGroup]: data.id },
            registry: {
              addRecord: callback,
              updateRecord: () => {}
            }
          })
        }
      }
    },
    deleteEntity (data) {
      this.$http.delete(`${this.$config.api}/registryservice/registry/${this.registryId}/records/${data.id}`)
      this.$refs.Atree.remove(data.id)
    },
    async isQuickAddCard () {
      // Проверка на карточку быстрого добавления
      let registryData = new Registry({ id: this.registryId })
      let me = this
      let structure = await registryData.structure().first()
        .catch(() => { me.error = true })
      if (!structure) {
        return false
      }
      let quickAddCard = (structure.properties || []).find((item) => item.id === 'quick_add_card') || {}
      if (quickAddCard.value && quickAddCard.value.card_id) {
        return quickAddCard
      } else {
        return false
      }
    },
    openQuickAddCard (quickAddCard, parent) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (quickAddCard.value.width) {
        customClass += `window_width_${quickAddCard.value.width}`
      }
      let me = this
      this.$msgbox({
        customClass: customClass,
        message: h('registry-card', {
          props: {
            cardId: quickAddCard.value.card_id,
            registryId: this.registryId,
            parentContext: null,
            model: {},
            quick: true,
            initialData: { [this.defaultGroup]: this.selectedNode.id }
          },
          on: {
            'quick-add': async function (data) {
              let cardFast = await me.getFastCard(data)
              me.openRegistryCard({
                registryId: me.registryId,
                cardId: cardFast.id,
                cardName: cardFast.name,
                recordId: null,
                initialData: data,
                registry: {
                  addRecord: async (recordid) => {
                    let dataCard = await me.getCardData(recordid)
                    let customNode = me.buildNode(dataCard, true)
                    if (parent) {
                      me.$refs.Atree.append(customNode, parent)
                    } else {
                      let node = new Node({ parent: me.$refs.Atree.root, store: me.$refs.Atree.store, data: customNode })
                      node.level = 1
                      me.$refs.Atree.root.childNodes.push(node)
                    }
                  },
                  updateRecord: () => {}
                }
              })
              me.$msgbox.close()
            },
            cancelChanges: function () {
              me.$msgbox.close()
            }
          },
          key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    async loadNode (node, resolve) {
      if (this.registryId && this.defaultGroup) {
        if (node.level === 0) {
          resolve(await this.loadEntities())
        } else {
          resolve(await this.loadEntities(node.data.id))
        }
      }
    },
    async getFastCard (recordData = null) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    async getCardId (recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${this.registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${this.registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)
      return data.data[0]
    },
    async getCardData (recordid) {
      let dataCard = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/card/${recordid}`)
      return dataCard.data
    },
    async openCard (data, parent = null) {
      let card = await this.getCardId(data.id)
      if (card) {
        this.selectedNode = data
        let callback = async (recordid) => {
          let dataCard = await this.getCardData(recordid)
          let customNode = this.buildNode(dataCard, true)
          this.$set(this.$refs.Atree.getCurrentNode(), 'name', customNode.name)
          this.$set(this.$refs.Atree.getCurrentNode(), 'id', customNode.id)
        }
        if (this.frameGuid) {
          let frame = (this.getDashboardComponents()[`component_${this.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', this.frameGuid)
            return false
          }

          frame.openCard({ cardId: card.id, registryId: this.registryId, recordId: data.id, updateCallback: callback })
        } else {
          this.openRegistryCard({
            registryId: this.registryId,
            cardId: card.id,
            cardName: card.name,
            recordId: data.id,
            registry: {
              addRecord: () => {},
              updateRecord: callback
            }
          })
        }
      }
    },
    async loadEntities (id = null) {
      let response
      if (!id) {
        response = await this.$http
          .get(`${this.$config.api}/registryservice/registry/${this.registryId}/data?filter[0]=${this.defaultGroup},eq,[]&filter[1]=${this.dataFilters.join('&')}`)
      } else {
        response = await this.$http
          .get(`${this.$config.api}/registryservice/registry/${this.registryId}/data?filter[0]=${this.defaultGroup},eqx,${id} `)
      }
      return this.buildNode(response.data.data)
    },
    buildNode (response, addRecord = false) {
      if (addRecord) {
        return this.parceHtmlTemplate(response)
      }
      return response.map(this.parceHtmlTemplate)
    },
    parceHtmlTemplate (item) {
      let attributes = this.tree.htmlTemplate.match(/\{{(.*?)\}}/g) || []
      let label = this.tree.htmlTemplate
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        let value = item[attribute]
        try {
          value = JSON.parse(value)
        } catch (e) {}
        if (value instanceof Array) {
          value = value.map(items => items.name).join(',')
        }
        label = label.replace(`{{${attribute}}}`, value || '')
      })
      let hideAddCard = !this.checkConditions(this.tree.addBtnHidden, true, item)
      let deleteBtnHidden = !this.checkConditions(this.tree.deleteBtnHidden, true, item)
      let openBtnHidden = !this.checkConditions(this.tree.openBtnHidden, true, item)
      return {
        id: item.id,
        entity: item,
        name: label,
        hideAddCard: hideAddCard,
        deleteBtnHidden: deleteBtnHidden,
        openBtnHidden: openBtnHidden,
        children: []
      }
    }
  },
  mounted () {
    console.log(this.tree)
  }
}
</script>

<style lang="scss" scoped>
.a-tree .header_fixed {
  width: 100%;
  padding: 10px 0px 10px 0px;
  background-color: rgb(245 245 245);
  border-bottom: 1px solid rgb(228 231 237);
  margin-bottom: 10px;
  position: fixed;
  z-index: 3;
}
</style>
